del {
  color: #d73a49;
}
ins {
  color: #22863a;
}
.wmde-markdown hr {
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
ol {
  list-style-type: lower-alpha;
}
