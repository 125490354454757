body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:invalid {
  border: 1px #ff0000 solid;
  background-color: #fae3e3;
}

ol.speciallist {
  list-style: none;
  counter-reset: li;
}
ol.speciallist > li {
  counter-increment: li;
}
ol.speciallist > li::before {
  color: black;
  text-decoration: none;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
ol.speciallist > li.increment {
  counter-increment: li;
  display: hidden;
}
ol.speciallist > li.increment::before {
  display: none;
}
ol.speciallist > li.decrement {
  counter-increment: li -1;
  display: hidden;
}
ol.speciallist > li.decrement::before {
  display: none;
}
ol.speciallist > li.asis::before {
  content: counter(li);
}
ol.speciallist > li.asis-i::before {
  content: counter(li, lower-roman);
}
ol.speciallist > li.asis-a::before {
  content: counter(li, lower-alpha);
}

ol.speciallist > li.del::before {
  content: counter(li);
  color: red;
  text-decoration: line-through;
}
ol.speciallist > li.ins::before {
  content: counter(li);
  color: green;
  text-decoration: none;
}

ol.speciallist > li.del-i::before {
  content: counter(li, lower-roman);
  color: red;
  text-decoration: line-through;
}
ol.speciallist > li.ins-i::before {
  content: counter(li, lower-roman);
  color: green;
  text-decoration: none;
}
ol.speciallist > li.del-II::before {
  content: counter(li, upper-roman);
  color: red;
  text-decoration: line-through;
}
ol.speciallist > li.ins-II::before {
  content: counter(li, upper-roman);
  color: green;
  text-decoration: none;
}
ol.speciallist > li.del-a::before {
  content: counter(li, lower-alpha);
  color: red;
  text-decoration: line-through;
}
ol.speciallist > li.ins-a::before {
  content: counter(li, lower-alpha);
  color: green;
  text-decoration: none;
}
